import React from "react";
import BuyForm from "../components/buyForm";
import CtaOne from "../components/ctaOne";
import CtaTwo from "../components/ctaTwo";
import Footer from "../components/footer";
import MainBanner from "../components/mainBanner";
import TextPartOne from "../components/textPartOne";

function Home() {
  return (
    <div>
      <MainBanner />
      <TextPartOne />
      <CtaOne />
      <CtaTwo />
      <BuyForm />
      <Footer />
    </div>
  );
}

export default Home;
