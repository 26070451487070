import { css } from "@emotion/css";
import ar from "../languages/ar.json";
import TextPart from "../common/textPart";

function CtaOne() {
  return (
    <div className={styles.container}>
      <TextPart heading={ar.ctaOneHeading} paragraph={ar.ctaOneParagraph} containerStyle="ctaOneTextPart" />
      <div className="ctaOneImgContainer">
        <img src="/img/pain-place.jpg" alt="pain-emotion" />
      </div>
    </div>
  );
}

export default CtaOne;

const styles = {
  container: css`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 60px;
    box-sizing: border-box;
    direction: rtl;
    .ctaOneImgContainer {
      width: 40%;
    }
    .ctaOneImgContainer img {
      width: 100%;
    }
    .ctaOneTextPart {
      background: #fff;
      width: 60%;
      text-align: right;
      padding: 0 50px;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      display: block;
      .ctaOneImgContainer {
        width: 100%;
      }
      img {
        width: 100%;
      }
      .ctaOneTextPart {
        background: #fff;
        width: 100%;
        text-align: center;
        padding: 0 0;
      }
    }
  `,
};
