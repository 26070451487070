const colors = {
  primary: "#473b8e",
  secondary: "#7031CC",
  mainFont: "#707070",
  divider: "#00000005",
  success: "#28a745",
  warning: "#ff3f3f",
};

export default colors;
