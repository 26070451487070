import { css } from "@emotion/css";
import ar from "../languages/ar.json";
import TextPart from "../common/textPart";

function TextPartOne() {
  return <TextPart heading={ar.textPartOneHeading} paragraph={ar.textPartOneParagraph} containerStyle={styles.container} />;
}

export default TextPartOne;

const styles = {
  container: css`
    direction: rtl;
    text-align: center;
  `,
};
