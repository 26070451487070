import { css } from "@emotion/css";
import Button from "../common/button";
import ar from "../languages/ar.json";

function MainBanner() {
  return (
    <div className={styles.container}>
      <div className="blurLayer" />
      <img src="/img/The-End-of-Elbow-Pain.jpeg" alt="elbow-pain-main-banner" />
      <h1>{ar.mainBannerHeading}</h1>
      <p>{ar.mainBannerParagraph}</p>
      <div className="buttonContainer">
        <Button containerStyle="button" text={ar.mainBannerButton} href="#buyForm" />
      </div>
    </div>
  );
}

export default MainBanner;

const styles = {
  container: css`
    width: 100%;
    position: relative;
    overflow: hidden;
    .blurLayer {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #00000099;
      z-index: 1;
    }
    img {
      width: 100%;
      z-index: 0;
    }
    h1 {
      position: absolute;
      top: calc(25% - 43px);
      color: #fff;
      font-size: 68px;
      width: 100%;
      text-align: center;
      text-shadow: 2px 2px #ff0000;
      z-index: 2;
    }
    p {
      position: absolute;
      top: 50%;
      color: #fff;
      font-size: 32px;
      width: 100%;
      text-align: center;
      text-shadow: 2px 2px #ff0000;
      z-index: 2;
    }
    .buttonContainer {
      position: absolute;
      width: 100%;
      bottom: 10%;
    }
    .button {
      width: 50px;
      margin: 0 auto;
      background: none;
    }
    @media screen and (max-width: 700px) {
      h1 {
        font-size: 2rem;
        margin: 0;
        top: 5%;
      }
      p {
        font-size: 1.2rem;
        margin: 0;
        padding: 0 20px;
        box-sizing: border-box;
        top: 35%;
      }
      .buttonContainer {
        width: 100%;
        bottom: 10px;
      }
      .button {
        min-width: 120px;
        margin: 0 auto;
        background: none;
        .btn {
          font-size: 11px;
          height: 0px;
          font-weight: bold;
        }
      }
    }
  `,
};
