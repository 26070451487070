import { useState, useEffect } from "react";
import { css } from "@emotion/css";
import Button from "../common/button";
import TextInput from "../common/TextInput";
import TextPart from "../common/textPart";
import ApplePayIcon from "../icons/applePayIcon";
import MasterCardIcon from "../icons/masterCardIcon";
import VisaIcon from "../icons/visaIcon";
import FailureIcon from "../icons/failureIcon";
import ar from "../languages/ar.json";
import PhoneInput from "../common/phone-input/phoneInput";
import validator from "validator";
import fetcher from "../auth/fetcher";
import Modal from "@mui/material/Modal";
import colors from "../constants/colors";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MadaIcon from "../icons/madaIcon";

const theme = createTheme({
  palette: {
    primary: {
      main: "#eee",
    },
  },
});

function BuyForm() {
  const navigate = useNavigate();
  const [info, setInfo] = useState({ name: "", email: "", phoneNumber: "" });
  const [products, setProducts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: { isValid: true, message: "" },
    email: { isValid: true, message: "" },
    phoneNumber: { isValid: true, message: "" },
  });

  useEffect(() => {
    fetcher.get("/products").then((results) => {
      setProducts(results.data);
    });
    const localOrder = JSON.parse(localStorage.getItem("order"));

    if (localOrder) {
      setIsLoading(true);
      fetcher
        .post("/order/orderCheck", { orderId: localOrder.orderId })
        .then((results) => {
          localStorage.removeItem("order");
          setIsLoading(false);
          navigate("/thank-you");
        })
        .catch((error) => {
          fetcher
            .post("/order/cancel", { orderId: localOrder.orderId })
            .then(() => {
              return;
            })
            .catch((error) => {
              return;
            })
            .finally(() => {
              setIsLoading(false);
              setIsOpen(true);
            });
          localStorage.removeItem("order");
        });
    }
  }, []);

  const nameValidate = !validator.isEmpty(info.name);
  const emailValidate = validator.isEmail(info.email);
  const phoneValidate = !validator.isEmpty(info.phoneNumber);

  function handleChange(e) {
    const { name, value } = e.target;
    setInfo((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: { isValid: true, message: "" } }));
  }

  function hadlePhoneInputChange(value) {
    setInfo((prev) => ({ ...prev, phoneNumber: value }));
    setErrors((prev) => ({ ...prev, phoneNumber: { isValid: true, message: "" } }));
  }

  function handleCheckout() {
    if (!nameValidate || !emailValidate || !phoneValidate) {
      setErrors({
        name: { isValid: nameValidate, message: "Name Cannot be empty!" },
        email: { isValid: emailValidate, message: "Email is not Valid" },
        phoneNumber: { isValid: phoneValidate, message: "Mobile number is not Valid" },
      });
    } else {
      setIsLoading(true);
      fetcher
        .post("/order", { ...info, productId: products[0].id })
        .then((results) => {
          const { id, reference } = results.data.result.order;
          localStorage.setItem("order", JSON.stringify({ orderId: id, reference, isRedirected: true }));
          return results.data.result.checkoutData.postUrl;
        })
        .then((url) => {
          window.location.href = url;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleModalClose() {
    setIsOpen(false);
  }

  return (
    <div className={styles.container} id="buyForm">
      <div className="offerOverlay"></div>
      <TextPart
        headingClassName="heading"
        heading={ar.buyHeading}
        paragraph={
          <span>
            {ar.buyParagraphParts.partOne} <span className="price">25</span> {ar.buyParagraphParts.partTwo}
          </span>
        }
        containerStyle="textPartContainer"
      >
        <p className="buyNote">{ar.buyNote}</p>
      </TextPart>
      <div className="textInputContainer">
        <TextInput
          error={!errors.name.isValid}
          helperText={!nameValidate && errors.name.message}
          onChange={handleChange}
          name="name"
          label="Name"
          className="textInput"
          value={info.name}
        />
      </div>
      <div className="textInputContainer">
        <TextInput
          error={!errors.email.isValid}
          helperText={!emailValidate && errors.email.message}
          onChange={handleChange}
          name="email"
          label="E-mail"
          className="textInput"
          value={info.email}
        />
      </div>
      <div className="textInputContainer">
        <PhoneInput
          error={!errors.phoneNumber.isValid}
          helperText={!phoneValidate && errors.phoneNumber.message}
          onChange={hadlePhoneInputChange}
          label="Mobile number"
          className="textInput"
          value={info.phoneNumber}
        />
      </div>
      <div className="buttonContainer">
        <Button onClick={handleCheckout} containerStyle="button" className="btn" text={ar.buyButton} />
      </div>
      <p className="buyButtonNote">{ar.buyButtonNote}</p>
      <div className="paymentIconsContainer">
        <div className="iconContainer">
          <MasterCardIcon size={80} />
        </div>
        <div className="iconContainer">
          <VisaIcon size={80} />
        </div>
        <div className="iconContainer">
          <ApplePayIcon size={80} color="#fff" />
        </div>
        <div className="iconContainer">
          <MadaIcon size={80} color="#fff" />
        </div>
      </div>
      <p className="contactUs">{ar.contactUs}: info@jesmek.com</p>
      <Modal onClose={handleModalClose} open={isOpen}>
        <div className={styles.modalContentContainer}>
          <FailureIcon size={100} color={colors.warning} />
          <h4>{ar.paymentFail}</h4>
          <Button onClick={handleModalClose} containerStyle="button" className="btn" text={ar.paymentFailButton} />
        </div>
      </Modal>
      <Modal onClose={handleModalClose} open={isLoading}>
        <div className={styles.loadingModalContentContainer}>
          <ThemeProvider theme={theme}>
            <CircularProgress size={80} color="primary" />
          </ThemeProvider>
        </div>
      </Modal>
    </div>
  );
}

export default BuyForm;

const styles = {
  container: css`
    background-image: url("/img/national-day.webp");
    text-align: center;
    direction: rtl;
    position: relative;
    .offerOverlay {
      /* background: #353535; */
      background: #000000bb;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .textPartContainer {
      background: none;
      position: relative;
    }
    .heading {
      color: #fff;
    }
    p {
      color: #fff;
    }
    .price {
      color: ${colors.warning};
      font-size: 30px;
      font-weight: bold;
    }
    .buyNote {
      font-weight: 800;
      color: #ffecbd;
    }
    .textInputContainer {
      width: 100%;
      margin-bottom: 10px;
    }
    .textInput {
      width: 70%;
      direction: ltr;
      .MuiOutlinedInput-root {
        color: #ffecbd;
      }
      .MuiInputLabel-root {
        color: #ffecbd;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #ffecbd;
      }
      .Mui-focused {
        color: #fff;
      }
      .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }
    }
    .buttonContainer {
      padding-top: 30px;
    }
    .button {
      width: 50px;
      margin: 0 auto;
      background: none;
    }
    .btn {
      font-size: 18px;
      font-weight: bold;
    }
    .buyButtonNote {
      font-size: 14px;
      margin: 0;
      padding-top: 5px;
      padding-bottom: 20px;
    }
    .paymentIconsContainer {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
    }
    .iconContainer {
      padding: 0 20px;
    }
    .contactUs {
      direction: rtl;
      padding-bottom: 70px;
      position: relative;
    }
  `,
  modalContentContainer: css`
    width: 300px;
    height: 300px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    text-align: center;
    .button {
      width: 50px;
      margin: 20px auto;
      background: #bebebe;
    }
    .btn {
      font-size: 18px;
      font-weight: bold;
      border-color: #bebebe;
      color: #313131;
    }
  `,
  loadingModalContentContainer: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
  `,
};
