import { css } from "@emotion/css";
import dayjs from "dayjs";

function Footer() {
  return (
    <div className={styles.container}>
      <p>© {dayjs().format("YYYY")} Jesmek. All rights reserved.</p>
    </div>
  );
}

export default Footer;

const styles = {
  container: css`
    text-align: center;
    background-color: #ffecbd;
    padding: 10px 0;
  `,
};
