import "./App.css";
import MainRouter from "./router/mainRouter";

function App() {
  return (
    <div>
      <MainRouter />
    </div>
  );
}

export default App;
