import { useState, memo } from "react";
import countriesData from "./CountriesCodes.json";
import ListItemButton from "@mui/material/ListItemButton";
import TextInput from "../TextInput";
import Card from "./card";
import List from "@mui/material/List";
import { css } from "@emotion/css";

function filterCountries(searchValue) {
  const filterdCountries = countriesData.filter((country) => {
    if (!searchValue) {
      return country;
    } else if (country.name.toLowerCase().includes(searchValue.toLowerCase())) {
      return country;
    } else {
      return null;
    }
  });

  return filterdCountries;
}

function SearchListItems({ onItemClick, currentValue }) {
  const [searchValue, setSearchValue] = useState("");

  function handleSearch(e) {
    const { value } = e.target;
    setSearchValue(value);
  }

  return (
    <div className={styles.container}>
      <TextInput name="search" value={searchValue} className="search-input" onChange={handleSearch} label="Search.." />
      <Card className="results-wrapper">
        <List className="list-container">
          {filterCountries(searchValue).map((country) => (
            <ListItemButton onClick={onItemClick} selected={currentValue === country.code} key={country.code} id={country.code}>
              <img
                src={`https://flagcdn.com/${country.code === "AC" || country.code === "TA" ? "sh" : country.code.toLowerCase()}.svg`}
                style={{ marginRight: 10 }}
                width="30"
                alt={country.name}
              />
              <label>
                {country.name} {country.dial_code}
              </label>
            </ListItemButton>
          ))}
        </List>
      </Card>
    </div>
  );
}

export default memo(SearchListItems);

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .search-input {
      width: 100%;
    }
    .results-wrapper {
      background: #fff;
      overflow: hidden;
      padding: 20px 0;
      margin: 20px 0;
      width: 100%;
    }
    .list-container {
      max-height: 300px;
      width: 100%;
      overflow-y: scroll;
    }
    @media (max-width: 700px) {
      .list-container {
        min-height: 75vh;
      }
    }
  `,
};
