import { css } from "@emotion/css";

function TextPart({ heading, paragraph, children, containerStyle, headingClassName, paragraphClassName }) {
  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <h2 className={headingClassName}>{heading}</h2>
      <p className={paragraphClassName}>{paragraph}</p>
      {children}
    </div>
  );
}

export default TextPart;

const styles = {
  container: css`
    background: #ffecbd;
    padding: 30px;
    h2 {
      color: #333333;
      font-size: 28px;
      width: 100%;
    }
    p {
      color: #333333;
      font-size: 18px;
      width: 100%;
    }
  `,
};
