import { css } from "@emotion/css";
import countriesData from "./CountriesCodes.json";
import Modal from "@mui/material/Modal";
import Card from "./card";
import { IconButton } from "@mui/material";
import SearchListItems from "./searchListItems";
import Slide from "@mui/material/Slide";
import CloseIcon from "./icons/closeIcon";

function CountrySelect({ onCountryChange, isOpen, onClose, currentCountry }) {
  function handleSelect(e) {
    const { id } = e.currentTarget;
    const currentSelected = countriesData.find((country) => country.code === id);
    onCountryChange && onCountryChange(currentSelected);
  }

  return (
    <Modal open={isOpen} className={styles.container} onClose={onClose}>
      <Slide direction="up" in={isOpen}>
        <div>
          <Card className="content-wrapper">
            <IconButton onClick={onClose} className="close-icon">
              <CloseIcon size={16} color="#707070" />
            </IconButton>
            <div>
              <SearchListItems onItemClick={handleSelect} currentValue={currentCountry} />
            </div>
          </Card>
        </div>
      </Slide>
    </Modal>
  );
}

export default CountrySelect;

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .content-wrapper {
      position: relative;
      padding: 30px;
      padding-top: 50px;
      box-shadow: 0px 25px 33px #00000057;
    }
    .close-icon {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    @media (max-width: 700px) {
      display: block;
      .content-wrapper {
        box-sizing: border-box;
        border-radius: 0;
        width: 100%;
      }
    }
  `,
  selectContainer: css`
    .MuiSelect-select {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .MuiSelect-select label {
      display: none;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  `,
};
