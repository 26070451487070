import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/home";
import NotFound from "../pages/notFound";
import ThankYou from "../pages/thankYou";

function MainRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="/thank-you" element={<ThankYou />} />
    </Routes>
  );
}

export default MainRouter;
