import React from "react";
import { css } from "@emotion/css";
import ar from "../languages/ar.json";
import CheckMarkIcon from "../icons/checkMarkIcon";
import colors from "../constants/colors";
import Button from "../common/button";

function ThankYou() {
  return (
    <div className={styles.container}>
      <div className="background-filter">
        <div className="card">
          <CheckMarkIcon color={colors.success} size={80} />
          <h1>{ar.thankYouHeading}</h1>
          <p>{ar.thankYouParagraph}</p>
          <p className="note">{ar.thankYouNote}</p>
          <Button containerStyle="button" className="btn" href="/" text={ar.thankYouButton} />
        </div>
      </div>
    </div>
  );
}

export default ThankYou;

const styles = {
  container: css`
    background-image: url("/img/fitness-pattern.png");
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    .background-filter {
      width: 100%;
      height: 100%;
      background-color: #ffffffc9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .card {
      min-width: 300px;
      min-height: 300px;
      background: #fff;
      box-shadow: 0px 0px 50px 0px #7e7e7e;
      border-radius: 30px;
      text-align: center;
      padding: 50px;
      margin: 0 100px;
      direction: rtl;
    }
    h1 {
      margin: 0;
    }
    p {
      font-size: 22px;
    }
    .note {
      color: #ff4242;
      font-size: 16px;
      font-weight: bold;
      margin-top: 30px;
    }
    .button {
      background: #929292;
      width: 100px;
      margin: 50px auto;
    }
    .btn {
      border-color: #929292;
    }
  `,
};
