function CheckMarkIcon({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || "512"}
      height={size || "512"}
      x="0"
      y="0"
      enableBackground="new 0 0 335.765 335.765"
      version="1.1"
      viewBox="0 0 335.765 335.765"
      xmlSpace="preserve"
      fill={color || "#000"}
    >
      <path d="M311.757 41.803L107.573 245.96 23.986 162.364 0 186.393 107.573 293.962 335.765 65.795z"></path>
    </svg>
  );
}

export default CheckMarkIcon;
