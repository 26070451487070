import { css } from "@emotion/css";

function Card({ children, className, cardRef, ...props }) {
  return (
    <div {...props} ref={cardRef} className={`${styles.container} ${className || ""}`}>
      {children}
    </div>
  );
}

export default Card;

const styles = {
  container: css`
    min-height: 300px;
    min-width: 300px;
    border-radius: 15px;
    margin: 0 auto;
    background: #eee;
    --box-shadow-color: #b3b3b3;
    box-shadow: 0px 0px 17px var(--box-shadow-color);
  `,
};
