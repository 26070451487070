import { useEffect, useState, useRef } from "react";
import { css } from "@emotion/css";
import TextInput from "../TextInput";
import CountrySelect from "./countrySelect";
import Button from "@mui/material/Button";
import countriesData from "./CountriesCodes.json";

// const reg =
//   /(?:\+|00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/;
const matchReg =
  /(\+?|00)(1869|1758|1784|297|290|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)/;
const plusSignReg = /\+/;

function PhoneInput({ onCountryChange, onChange, value, defaultCountry, ...props }) {
  const defaultCountryData = countriesData.find((country) => country.code === defaultCountry.toUpperCase());
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [country, setCountry] = useState(defaultCountryData);
  const [defaultValue, setDefaultValue] = useState(country.dial_code);

  useEffect(() => {
    const matchValue = value.match(matchReg) && value.match(matchReg)[2];
    const defaultCountryData = countriesData.find((country) => country.dial_code === "+" + matchValue);
    if (value) {
      setCountry(defaultCountryData);
    }
  }, [value]);

  useEffect(() => {
    const matchValue = value.match(matchReg) && value.match(matchReg)[2]; //return dial code without plus sign
    const removedDialCode = value.replace(matchValue, ""); //return value without country dial code
    const removedPlusSign = removedDialCode.replace(plusSignReg, ""); //remove plus sign
    setDefaultValue(value ? country.dial_code + removedPlusSign : defaultValue);
  }, [defaultValue, value, country.dial_code]);

  function handleChange(e) {
    const zeroReg = /^(?:0+(?=[1-9])|0+(?=0$))|[^0-9]/gm;
    const matchValue = e.target.value.match(matchReg) && e.target.value.match(matchReg)[2];
    const removedDialCode = e.target.value.replace(matchValue, "");
    const removedPlusSign = removedDialCode.replace(plusSignReg, "");
    const removedZeroValue = removedPlusSign.replace(zeroReg, "");
    const finalValue = e.target.value.length >= country.dial_code.length ? removedZeroValue : "";
    const onChangeValue = country.dial_code + finalValue;
    onChange && onChange(finalValue ? onChangeValue.replace(plusSignReg, "") : "", country);
    !value && setDefaultValue(onChangeValue);
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleCountryChange(newCountry) {
    setCountry(newCountry);
    const matchValue = value.match(matchReg) && value.match(matchReg)[2]; //return dial code without plus sign
    const removedDialCode = value.replace(matchValue, ""); //return value without country dial code
    const removedPlusSign = removedDialCode.replace(plusSignReg, ""); //remove plus sign
    const finalValue = newCountry.dial_code + removedPlusSign;
    onCountryChange && onCountryChange(newCountry);
    if (!value) {
      setDefaultValue(newCountry.dial_code + defaultValue.replace(country.dial_code, ""));
      onChange && onChange(newCountry.dial_code.replace(plusSignReg, "") + defaultValue.replace(country.dial_code, ""), newCountry);
    } else {
      setDefaultValue(finalValue);
      onChange && onChange(finalValue ? finalValue.replace(plusSignReg, "") : "", newCountry);
    }

    handleClose();
  }

  return (
    <div className={styles.container}>
      <TextInput
        {...props}
        onChange={handleChange}
        type="tel"
        autoComplete="tel-country-code"
        InputProps={{
          startAdornment: (
            <Button onClick={handleOpen} className="flag-button" variant="text">
              <img src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`} width="40" alt={defaultValue} />
            </Button>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        label="Phone"
        value={defaultValue}
        id="phone"
        inputRef={inputRef}
      />
      <CountrySelect isOpen={isOpen} onClose={handleClose} onCountryChange={handleCountryChange} currentCountry={country.code} />
    </div>
  );
}

PhoneInput.defaultProps = {
  defaultCountry: "SA",
  value: "",
};

export default PhoneInput;

const styles = {
  container: css`
    position: relative;
    .Text-input {
      width: 100%;
    }
    .flag-button {
      background: #eee;
      min-width: 0;
      margin-right: 10px;
    }
  `,
};
