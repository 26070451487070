import { css } from "@emotion/css";
import ar from "../languages/ar.json";
import TextPart from "../common/textPart";
import Button from "../common/button";

function CtaTwo() {
  return (
    <div className={styles.container}>
      <div className="ctaTwoImgContainer">
        <img src="/img/pain-emotion.jpg" alt="pain-emotion" />
      </div>
      <TextPart heading={ar.ctaTwoHeading} paragraph={ar.ctaTwoParagraph} containerStyle="ctaTwoTextPart">
        <div className="buttonContainer">
          <Button href="#buyForm" containerStyle="button" className="btn" text={ar.mainBannerButton} />
        </div>
      </TextPart>
    </div>
  );
}

export default CtaTwo;

const styles = {
  container: css`
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    direction: rtl;
    background: #ffecbd;
    .ctaTwoImgContainer {
      width: 50%;
    }
    .ctaTwoImgContainer img {
      width: 100%;
    }
    .ctaTwoTextPart {
      width: 50%;
      text-align: left;
      padding: 0 50px;
    }
    .buttonContainer {
      padding: 30px 0;
    }
    .button {
      width: 50px;
      margin: 0 auto;
    }
    .btn {
      color: #404040;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
      display: block;
      .ctaTwoImgContainer {
        width: 100%;
      }
      img {
        width: 100%;
      }
      .ctaTwoTextPart {
        width: 100%;
        text-align: center;
        padding: 0 30px;
        box-sizing: border-box;
      }
    }
  `,
};
